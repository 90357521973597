import React from "react"
import { graphql, StaticQuery } from "gatsby"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import CommingSoon from "../components/Snap/CommingSoon"

const BlogIndex = ({ data }, location) => {
  // const siteTitle = data.site.siteMetadata.title

  return (
    <>
      <CommingSoon></CommingSoon>
    </>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
