import React from "react"
import styled, { keyframes } from "styled-components"
import SnapLogo from "../../../assets/logo/snap.svg"

export const moveAnimationOne = keyframes`
 0% { transform: translate(20vw, 10vh) }
 10% { transform: translate(0vw, 20vh) }
 50% { transform: translate(60vw, 90vh) }
 60% { transform: translate(80vw, 60vh) }
 100% { transform: translate(20vw, 10vh)  }
`

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f4d46a;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 279px;
  margin-bottom: 120px;
  position: fixed;
  animation: ${moveAnimationOne} 30s linear infinite;
`

const Text = styled.h1`
  font-size: 32px;
  font-weight: 300;
  color: black;
`

const CommingSoon = () => {
  return (
    <>
      <Container>
        <Logo src={SnapLogo} />
        <Text>Coming soon...</Text>
      </Container>
    </>
  )
}

export default CommingSoon
